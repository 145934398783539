<template>
  <device-report-list></device-report-list>
</template>

<script>
import { BTable, BModal } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import api from "@/service/api";
import utils from "@/service/utils";
import DeviceReportList from "./reports/DeviceReportList.vue";
export default {
  created() {},
  components: {
    DeviceReportList,
  },
};
</script>

<style>
</style>